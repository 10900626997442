import React from 'react'
import { Grid } from '@mui/material'
import logo from '../../Assets/navbar/logo.png'
import Logo from '../../Assets/navbar/logo.png'
import { Link } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import './newnav.css'

const NewNavbar = ({navOn, handleClick}) => {

  return (
    <>
        <div className="flex w-full text-white mynavclass border-b fixed bg-black pb-2 pt-2 justify-between h-28" style={{zIndex: '99999'}}>
            <div className="flex basis-3/12 flex-grow-2 flex-shrink justify-end items-center gap-2 overflow-hidden">
                <Link to='/' className='flex overflow-hidden items-center hover:cursor-pointer min-w-24 w-24 '>
                    <img src={logo} alt="no img" className='w-full rounded-full bg-white hover:bg-[#08fdd8]' />
                </Link>
                <Link to='/' className='flex'><h1 className='text-2xl text-crimson-rgb font-semibold mt-2'>Excyan</h1></Link>
            </div>
            <div className="flex basis-9/12 items-center ml-2 flex-grow-2 flex-shrink">
                <div className="flex basis-5/6 flex-col h-full justify-between flex-grow flex-shrink">
                    <div className="flex w-full h-1/2 items-center">
                        <ul className='flex justify-center gap-8 w-full flex-wrap pl-4'>
                        <Link to='/' style={{textDecoration: 'none', color: 'white'}}><li className='hover:text-crimson-rgb hover:cursor-pointer'>Home</li></Link>
                            <Link to='/About-Us' style={{textDecoration: 'none', color: 'white'}}><li className='hover:text-crimson-rgb hover:cursor-pointer'>About Us</li></Link>
                            {/* <Link to='/Case-Studies' style={{textDecoration: 'none', color: 'white'}}><li className='hover:text-crimson-rgb hover:cursor-pointer'>Case Studies</li></Link> */}
                            {/* <Link to='/Blog' style={{textDecoration: 'none', color: 'white'}}><li className='hover:text-crimson-rgb hover:cursor-pointer'>Blog</li></Link> */}
                            <Link to='/How-it-works' style={{textDecoration: 'none', color: 'white'}}><li className='hover:text-crimson-rgb hover:cursor-pointer'>How it works</li></Link>
                            {/* <a href='https://www.vividmarketingagency.org' rel='noreferrer' target="_blank" style={{textDecoration: 'none', color: 'white'}}><li className='hover:text-crimson-rgb hover:cursor-pointer'>Digital Marketing</li></a> */}
                        </ul>
                    </div>
                    <div className="flex w-full h-1/2 items-center flex-wrap">
                        <ul className='flex justify-around gap-2 flex-nowrap w-full pl-4 pr-4'>
                            <Link to='/Product-Engineering' style={{textDecoration: 'none', color: 'white'}}><li className='hover:text-crimson-rgb hover:cursor-pointer text-nowrap'>Product Engineering</li></Link>
                            <Link to='/Cloud-and-DevOps-Engineering' style={{textDecoration: 'none', color: 'white'}}><li className='hover:text-crimson-rgb hover:cursor-pointer text-nowrap'>Cloud & DevOps Engineering</li></Link>
                            <Link to='/Data-Engineering' style={{textDecoration: 'none', color: 'white'}}><li className='hover:text-crimson-rgb hover:cursor-pointer text-nowrap'>Data Engineering</li></Link>
                            <Link to='/Data-Science' style={{textDecoration: 'none', color: 'white'}}><li className='hover:text-crimson-rgb hover:cursor-pointer text-nowrap'>Data Science</li></Link>
                            <Link to='/Quality-Engineering' style={{textDecoration: 'none', color: 'white'}}><li className='hover:text-crimson-rgb hover:cursor-pointer text-nowrap'>Quality Engineering</li></Link>
                        </ul>
                    </div>
                </div>
                <div className="flex justify-center basis-1/6 flex-grow flex-shrink">
                    <Link to='/Contact-Us' style={{textDecoration: 'none', color: 'white'}}>
                        <button className='nav-button'>Contact Us</button>
                    </Link>
                </div>
            </div>
        </div>
        <Grid sx={{display: {xs: 'flex', lg: 'none'}}} container justifyContent='space-between' alignItems='center' xs={12} style={{ position: 'fixed',minHeight: "70px", maxHeight: "70px",zIndex: '999', background: '#1DB5CA'}}>
            {
            navOn ? <></> :
                <Grid container xs={12} justifyContent='space-between' alignItems='center' sx={{height: '70px'}}>
                    <Grid container xs={6} justifyContent='center' alignItems='center' sx={{height: '100px', overflow: 'hidden'}}>
                        <Link to='/' style={{textDecoration: 'none', color: 'white', display: 'flex', width: '100%', height: '100%', marginTop: '-1.75rem'}}><img src={Logo} className='logo-style' alt="logo" /></Link>
                    </Grid>
                    <Grid container xs={6} justifyContent='end' pr={2} alignItems='center'>
                        <MenuIcon onClick={handleClick} sx={{fontSize: "30px", marginTop: '-1.75rem'}}  />
                    </Grid>
                </Grid>
            } 
        </Grid>
    </>
  )
}

export default NewNavbar