import React from 'react'
import Particlesjs from '../Home/Particlesjs';
import { Container, Grid, Typography } from '@mui/material';
const Blog = () => {
  return (
    <Container>
      <Particlesjs />
      <Grid xs={12} mt={24} className='text-white'>
          <Typography textAlign={'center'} variant='h6'>This Feature is Under Construction</Typography>
      </Grid>
    </Container>
  )
}

export default Blog