import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import './App.css';
import SharedLayout from './Components/SharedLayout/SharedLayout';
import Home from './Components/Home/Home';
import ContactUs from './Components/Contact-Us/ContactUs';
import NewNavbar from './Components/Navbar/NewNavbar';
import AboutUs from './Components/Software-Development/AboutUs';
import CloudDev from './Components/Software-Development/CloudDev'
import DataEngineering from './Components/Software-Development/DataEngineering'
import ProductEngineering from './Components/Software-Development/ProductEngineering'
import QualityEngineering from './Components/Software-Development/QualityEngineering';
import CaseStudies from './Components/Software-Development/CaseStudies';
import HowItWorks from './Components/Software-Development/HowItWorks';
import Blog from './Components/Blog/Blog';

function App() {
  // eslint-disable-next-line
  const [darkMode, setdarkMode] = useState(false)

  const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
          main: '#fff'
        },
        jey: {
          a0: 'white',
          head: 'crimson',
          header: 'crimson',
          bordercolor: 'white',
          o1Clr: 'hsl(50, 100%, 60%)',
          a0Clr: 'hsl(220, 50%, 20%)',
          a1Clr: 'hsl(220, 65%, 26%)',
          a2Clr: 'hsl(220, 50%, 30%)',
          a3Clr: 'hsl(50, 90%, 85%)',
          a4Clr: 'hsl(50, 100%, 96%)',
          b0Clr: 'hsl(210, 80%, 9%)',
          b1Clr: 'hsl(210, 80%, 12%)',
          b2Clr: 'hsl(210, 100%, 25%)',
          b3Clr: 'hsl(214, 100%, 84%)',
          b4Clr: 'hsl(214, 100%, 94%)',
          bg: 'black'
        }
    }
  });

  const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
          main: '#ffff'
        },
        jey: {
          a0: 'black',
          head: 'blue',
          header: 'crimson',
          bordercolor: 'black',
          o1Clr: 'hsl(100, 100%, 30%)',
          a0Clr: 'hsl(60, 100%, 97%)',
          a1Clr: 'hsl(50, 100%, 95%)',
          a2Clr: 'hsl(50, 100%, 85%)',
          a3Clr: 'hsl(45, 100%, 77%)',
          a4Clr: 'hsl(50, 100%, 28%)',
          b0Clr: 'hsl(213, 100%, 96%)',
          b1Clr: 'hsl(214, 100%, 93%)',
          b2Clr: 'hsl(214, 100%, 84%)',
          b3Clr: 'hsl(214, 65%, 43%)',
          b4Clr: 'hsl(214, 100%, 25%)',
          bg: 'black'
        }
    }
  });

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
    <CssBaseline />
    <GlobalStyles
        styles={{
          body: { background: darkMode ? 'linear-gradient(to right, hsl(214, 50%, 10%) 0%, hsl(214, 20%, 9%) 20%, hsl(214, 20%, 9%) 50%, hsl(214, 20%, 9%) 80%, hsl(214, 50%, 10%) 100%)' : '#F9FAFB' }
        }}
      />
      <Routes>
        <Route path='/' element={<SharedLayout />}>
          <Route index element={<Home />}/>
          <Route path='Contact-Us' element={<ContactUs />}/>
          <Route path='About-Us' element={<AboutUs />}/>
          {/* <Route path='Case-Studies' element={<CaseStudies />}/> */}
          <Route path='Product-Engineering' element={<ProductEngineering />}/>
          <Route path='Cloud-and-DevOps-Engineering' element={<CloudDev />}/>
          <Route path='Data-Engineering' element={<DataEngineering />}/>
          <Route path='Data-Science' element={<DataEngineering />}/>
          <Route path='Quality-Engineering' element={<QualityEngineering />}/>
          <Route path='How-It-Works' element={<HowItWorks />}/>
          <Route path='Blog' element={<Blog />}/>
        </Route>
        <Route path='newnav' element={<NewNavbar />}/>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
