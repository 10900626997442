import { Alert, Container, Grid, Input, Stack, Typography } from '@mui/material'
import React, { useState, useRef } from 'react'
import emailjs from '@emailjs/browser';

import pic1 from '../../Assets/pic10.jpg'
import pic2 from '../../Assets/pic13.jpg'
import pic3 from '../../Assets/pic14.jpg'

import ParticlesG from './ParticlesG';

const AboutUs = () => {
  const [formData, setformData] = useState({Name:'',Email:'',PhoneNumber:'', Website:'', Description:''})
    const [success, setsuccess] = useState(false)

    if(success) {
        setTimeout(() => {
            setsuccess(false)
        }, 3000);
    }
    const form = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('x');
        emailjs.sendForm('service_p1co2fb', 'template_fqmixhf', form.current, '-yh_aCVLqtSwBXbKc')
        setformData({Name:'',Email:'',PhoneNumber:'', Website:'', Description:''})
    }

    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setformData({...formData, [name]: value})
    }
  return (
    <>
        <ParticlesG />
        <Container>
            <Grid mt={10} pt={10} container xs={12} justifyContent='center' alignItems='center' gap='5rem' sx={{flexDirection: {lg: 'row', xs: 'column'}}}>
                <Grid container xs={12} lg={6} justifyContent='start' alignItems='flex-start'>
                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '3rem'}}} variant='h2' fontWeight={600} color='white'>Team that Engineers your Next <span style={{color:'#1AABE3'}}>Digital Product<br /></span></Typography></Grid>
                    <br />
                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}}} variant='h6' color='white'>Nearly every organization will need to become a tech company in order to compete tomorrow. Yes, even yours. At Excyan, we are on a mission to help companies develop competitiveness and agility using the software.</Typography></Grid>
                </Grid>
                <Grid container xs={12} lg={5} justifyContent='center' alignItems='center'>
                    <img src={pic1} style={{width: '100%', borderRadius: '10px'}} alt="" />
                </Grid>
            </Grid>
        </Container>

        {/* 
                    <br />
                     */}

        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12} justifyContent='center' alignItems='center' gap='5rem' sx={{flexDirection: {xs: 'column-reverse', lg: 'row'}}}>

                <Grid container justifyContent='center' alignItems='center' xs={12} lg={5} gap='1rem'>
                    <img src={pic2} style={{width: '100%', borderRadius: '5px'}} alt="" />
                </Grid>

                <Grid container xs={12} lg={6} justifyContent='start' alignItems='flex-start'>
                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '3rem'}}} color='white' variant='h3' fontWeight='600'>We hit wide - <span style={{color: '#1AABE3'}}>without <br /> shooting with a hail of bull</span>ets</Typography></Grid>
                    <br />
                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}}} color='white' variant='h6'>We are a product engineering company with a mission to solve complex software engineering problems. Founded in 2024, we provide services to organizations ranging from Startups that went public, to Fortune 500 companies, and progressive Enterprises.</Typography></Grid>
                </Grid>

            </Grid>
        </Container>

        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12} sx={{ justifyContent: {xs: 'center', lg: 'space-between'} }} gap='1rem'>
                <Grid container xs={12} lg={6} justifyContent='center' >
                    <Grid item xs={12} justifyContent='center'>
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '3rem'}}} color='white' variant='h3' fontWeight='600'><span style={{color: '#1AABE3'}}>Success</span> throughout</Typography>
                        <br />
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}}} color='white' variant='h6'>Our Product Innovation Center transforms your engineering from being bottlenecks to growth drivers. We help you to identify and solve critical business challenges with proven technology practices such as DevOps, cloud-native development, and quality engineering services. Our remote agile teams of engineers immerse themselves in your project, maintaining your company culture and working in line with your strategic goals.</Typography>
                    </Grid>
                </Grid>
                <Grid container xs={12} lg={5.5} justifyContent='center' >
                    <img src={pic3} style={{width: '100%', borderRadius: '5px'}} alt="" />
                </Grid>
            </Grid>
        </Container>



        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12} gap='5rem' sx={{ justifyContent: {xs: 'center', lg: 'space-between'}, alignItems: 'start', flexDirection: { xs: 'column', lg: 'row' } }}>
                <Grid container flex={1} gap='5rem'>
                    <Grid item><Typography color='white' variant='h4' fontWeight='600' sx={{fontSize: {xs: '1.5rem', md: '3rem'}}}>Want to know more about your options?</Typography></Grid>
                    <form style={{width: '100%', display: 'flex', gap: '2rem', flexDirection: 'column'}} ref={form} onSubmit={handleSubmit}>
                        <Grid container xs={12} justifyContent='space-between' gap='1rem'>
                            <Grid item  flex={1}><Input sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} required className='inputfieldy' type='text' name='Name' value={formData.Name} onChange={handleChange} placeholder="Name" fullWidth/></Grid>
                            <Grid item  flex={1}><Input sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} required className='inputfieldy' type='email' name='Email' value={formData.Email} onChange={handleChange} placeholder="Email" fullWidth/></Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent='space-between' gap='1rem'>
                            <Grid item flex={1}><Input sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} className='inputfieldy' type='text' name='PhoneNumber' value={formData.PhoneNumber} onChange={handleChange} placeholder="Phone Number (optional)" fullWidth/></Grid>
                            <Grid item flex={1}><Input sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} className='inputfieldy' type='text' name='Website' value={formData.Website} onChange={handleChange} placeholder="Website Domain" fullWidth/></Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent='space-between' gap='1rem'>
                            <Grid item flex={1}><Input sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} required className='inputfieldy' type='text' name='Description' value={formData.Description} onChange={handleChange} placeholder="Description Of Project" fullWidth/></Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent='space-between' gap='1rem'>
                            <button style={{backgroundColor: 'rgba(29, 29, 29, 1)'}} className='contact-button' type='submmit' onClick={()=> setsuccess(true)}>Submit</button>
                        </Grid>
                        <div>
                            {
                                success 
                                ? 
                                    <Stack sx={{ width: '70%' }} spacing={2}>
                                        <Alert sx={{backgroundColor: '#333', color: 'white', fontSize: '1.1rem'}} severity="success">Your Message was successfully sent!</Alert>
                                    </Stack>
                                : 
                                    <></>
                            }
                        </div>
                    </form>
                </Grid>
                <Grid container flex={1} gap='2rem'>
                    <Grid item xs={12}><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '2rem'}}} color='white' variant='h4' fontWeight='600'>Choose your own <span style={{color: '#1AABE3'}}>PATH</span></Typography></Grid>
                    <Grid item xs={12}><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)'}} color='white' variant='body1'>Unleash Your Potential</Typography></Grid>
                    <Grid item xs={12}><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)'}} color='white' variant='body1' fontWeight={600}>Let's overtake your competitors together</Typography></Grid>
                    <Grid container xs={12}>
                        <Grid container flex={1} flexDirection='column'>
                            <Grid item style={{color: 'white', backgroundColor: 'rgba(29, 29, 29, 1)'}} >Email</Grid>
                            <Grid item style={{color: 'white', backgroundColor: 'rgba(29, 29, 29, 1)'}} >Contact@excyan.com</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    </>
  )  
}

export default AboutUs